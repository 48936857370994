import { createGlobalStyle } from "styled-components";

import MontserratBold from "../../fonts/Montserrat-Bold.ttf";
import MontserratRegular from "../../fonts/Montserrat-Regular.ttf";

import RobotoRegular from "../../fonts/Roboto-Regular.ttf";
import RobotoBold from "../../fonts/Roboto-Bold.ttf";

import NunitoRegular from "../../fonts/Nunito-Regular.ttf";
import NunitoBold from "../../fonts/Nunito-Bold.ttf";

export const GlobalFonts = createGlobalStyle`

  @font-face {
    font-display: fallback;
    font-family:"Montserrat";
    src: url(${MontserratRegular}) format("truetype");
    font-display: swap;
    font-weight: 400;
  };

  @font-face {
    font-display: fallback;
    font-family:"Montserrat-Bold";
    src: url(${MontserratBold}) format("truetype");
    font-display: swap;
    font-weight: bold;
  };


  @font-face {
  font-family: "Roboto";
  src: url(${RobotoRegular}) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url(${RobotoBold}) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Nunito";
  src: url(${NunitoRegular}) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
  
@font-face {
  font-family: "Nunito-Bold";
  src: url(${NunitoBold}) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
`;

export const FONT_FAMILY = {
  MONTSERRAT: "font-family: Montserrat;",
  ROBOTO: "font-family: Roboto;",
};
